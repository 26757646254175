import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {handleUserAccess} from "../../Utils/Auth";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";

const Settings = props => {

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [settings, setSettings] = useState('');
    const [paypalEmail, setPayPalEmail] = useState('');

    const [papersList, setPapersList] = useState([]);
    const [paperName, setPapersName] = useState('');


    const [subjectsList, setSubjectsList] = useState([]);
    const [subjectName, setSubjectsName] = useState('');

    const [ratesList, setRatesList] = useState([]);
    const [service, setService] = useState('scratch');
     const [education, setEducation] = useState('university');
    const [less24, setLess24] = useState('');
    const [less72, setLess72] = useState('');
    const [more72, setMore72] = useState('');

    const papersTypeColumns = [
        {
            name: 'Name',
            sortable: true,
            cell: row => <div><Link to={"/paper/" + row.ID}>{row.title}</Link></div>,
        },
        {
            name: '',
            cell: row => <button className={"btn btn-sm btn-danger"}
                                 onClick={e => deletePapers(row.ID)}>Delete</button>,
        },
    ]

    function loadPapers() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.PAPER_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setPapersList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addPapers(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("name", paperName)

        setIsLoading(true)
        postData(Endpoints.PAPER_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setPapersName('');
                    loadPapers()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deletePapers(id) {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.PAPER_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.data, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadPapers()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    const subjectsTypeColumns = [
        {
            name: 'Name',
            sortable: true,
            cell: row => <div><Link to={"/subject/" + row.ID}>{row.title}</Link></div>,
        },
        {
            name: '',
            cell: row => <button className={"btn btn-sm btn-danger"}
                                 onClick={e => deleteSubjects(row.ID)}>Delete</button>,
        },
    ]

    function loadSubjects() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.SUBJECT_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setSubjectsList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addSubjects(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("name", subjectName)

        setIsLoading(true)
        postData(Endpoints.SUBJECT_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setSubjectsName('');
                    loadSubjects()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deleteSubjects(id) {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.SUBJECT_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.data, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadSubjects()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    const ratesColumns = [
        {
            name: 'Service',
            selector: 'service',
            sortable: true,
        },
        {
            name: 'Education',
            selector: 'education_level',
            sortable: true,
        },
        {
            name: '< 24 HRS',
            selector: 'less_24',
            sortable: true,
        },
        {
            name: '< 72 HRS',
            selector: 'less_72',
            sortable: true,
        },
        {
            name: '> 72 HRS',
            selector: 'more_72',
            sortable: true,
        },
        {
            name: '',
            cell: row => <button className={"btn btn-sm btn-danger"}
                                 onClick={e => deleteRate(row.ID)}>Delete</button>,
        },
    ]

    function loadRates() {
        let data = new FormData();
        setIsLoading(true)
        postData(Endpoints.RATE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setRatesList(res.data || []);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function addRates(e) {
        e.preventDefault()

        let data = new FormData();
        data.append("service", service)
                data.append("education", education)
        data.append("less_24", less24)
        data.append("less_72", less72)
        data.append("more_72", more72)

        setIsLoading(true)
        postData(Endpoints.RATE_ADD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    setLess24('')
                    setLess72('')
                    setMore72('')
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function deleteRate(id) {

        let data = new FormData();
        data.append("id", id);

        setIsLoading(true)
        postData(Endpoints.RATE_DELETE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(res.success === 1 ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);
                if (res.success === 1) {
                    loadRates()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    useEffect(() => {
        document.title = "Settings | " + Strings.APP_DESCRIPTION;

        if (globalState.userLoggedIn) {
            setName(globalState.currentUser.name);
            setEmailAddress(globalState.currentUser.email);
        }

        loadSettings()
        loadRates()
        loadPapers()
        loadSubjects()

    }, [globalState.userLoggedIn]);

    const submitProfileForm = e => {
        e.preventDefault();

        if (name === '') {
            Notify("Name is required", ERROR);
            return;
        }

        if (!(!!email)) {
            Notify("Email is required", ERROR);
            return;
        }

        let data = new FormData();
        data.append("name", name)
        data.append("email", email)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PROFILE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Profile updated successfully", SUCCESS);
                    handleUserAccess(res, globalActions, false);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const submitPasswordForm = e => {
        e.preventDefault();

        if (!(!!password)) {
            Notify("Current password is required", ERROR);
            return;
        }

        if (!(!!newPassword)) {
            Notify("New password is required", ERROR);
            return;
        }

        if (newPassword !== confirmPassword) {
            Notify("Please confirm your password", ERROR);
            return;
        }

        let data = new FormData();
        data.append("old_password", password)
        data.append("new_password", newPassword)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Password updated successfully", SUCCESS);
                    handleUserAccess(res, globalActions, false);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const submitSettings = e => {
        e.preventDefault();


        if (!(!!paypalEmail)) {
            Notify("Paypal email is required", ERROR);
            return;
        }

        let data = new FormData();
        data.append("paypal_email", paypalEmail)

        setIsLoading(true);

        postData(Endpoints.UPDATE_SETTINGS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Site settings updated successfully", SUCCESS);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    function loadSettings() {
        let data = new FormData();

        setIsLoading(true)
        postData(Endpoints.LIST_SETTINGS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1 && res.data.length > 0) {
                    setPayPalEmail(res.data[0].pay_pal_email);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Settings
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabSettings" role="tab" data-toggle="tab">
                                        General site settings
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabPapers" role="tab" data-toggle="tab">
                                        Papers
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabSubjects" role="tab" data-toggle="tab">
                                        Subjects
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRates" role="tab" data-toggle="tab">
                                        Rates
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabProfile" role="tab" data-toggle="tab">
                                        Update profile
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabPassword" role="tab" data-toggle="tab">
                                        Change password
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">


                            <div role="tabpanel" className="tab-pane fade in active show" id="tabSettings">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Paypal email
                                                        address</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Email"
                                                           value={paypalEmail}
                                                           onChange={e => setPayPalEmail(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitSettings(e)}>Update site
                                                            settings</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabPapers">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={papersTypeColumns}
                                            data={papersList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new paper</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addPapers}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={paperName}
                                                               onChange={e => setPapersName(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabSubjects">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={subjectsTypeColumns}
                                            data={subjectsList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add new paper</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addSubjects}>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Name</label>
                                                        <input type="text"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={subjectName}
                                                               onChange={e => setSubjectsName(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRates">
                                <div className="row">
                                    <div className="col-md-6">
                                        <DataTable
                                            columns={ratesColumns}
                                            data={ratesList}
                                            pointerOnHover={true}
                                            className={"table table-striped table-bordered"}
                                            noHeader={true}
                                            customStyles={Styles.customTableStyles}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <section
                                            className="box-typical box-typical-dashboard panel panel">
                                            <header className="box-typical-header panel-heading bg-dark">
                                                <h3 className="panel-title text-white">Add a rate</h3>
                                            </header>

                                            <div className="card-block">
                                                <form onSubmit={addRates}>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Service</label>
                                                        <select className="form-control"
                                                                value={service}
                                                                onChange={({target}) => setService(target.value)}>
                                                            <option value={"scratch"}>Writing from scratch</option>
                                                            <option value={"rewriting"}>Rewriting</option>
                                                            <option value={"editing"}>Editing</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label col-form-label-sm">Education</label>
                                                        <select className="form-control"
                                                                value={education}
                                                                onChange={({target}) => setEducation(target.value)}>
                                                            <option value={"high-school"}>High school</option>
                                                            <option value={"university"}>University</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Less than 24
                                                            hours</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={less24}
                                                               onChange={e => setLess24(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">Less than 72
                                                            hours</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={less72}
                                                               onChange={e => setLess72(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label
                                                            className="form-label col-form-label-sm">More than 72
                                                            hours</label>
                                                        <input type="number"
                                                               required={true}
                                                               className="form-control"
                                                               placeholder="Name"
                                                               value={more72}
                                                               onChange={e => setMore72(e.target.value)}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-primary"
                                                            >Add</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabProfile">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Name</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Title"
                                                           value={name}
                                                           onChange={e => setName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Email
                                                        address</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Email address"
                                                           value={email}
                                                           onChange={e => setEmailAddress(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitProfileForm(e)}>Update
                                                            profile</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabPassword">
                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Current
                                                        password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="Current password"
                                                           value={password}
                                                           onChange={e => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">New password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="New password"
                                                           value={newPassword}
                                                           onChange={e => setNewPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Confirm new
                                                        password</label>
                                                    <input type="password"
                                                           className="form-control"
                                                           placeholder="Confirm new password"
                                                           value={confirmPassword}
                                                           onChange={e => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={e => submitPasswordForm(e)}>Change
                                                            password</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>


                                </div>
                            </div>

                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default Settings;

