import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";


const TransactionListSection = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    const [requestsListAll, setRequestListAll] = useState([]);

    const columns = [

        {
            name: 'Date',
            selector: 'date_string',
        },
        {
            name: 'Type',
            selector: 'type',
        },
        {
            name: 'Description',
            selector: 'description',
        },
        {
            name: '',
            sortable: true,
            cell: row => row.order_id !== "" ? <Link to={"/order-details/" + row.order_id}
                                                     style={{fontWeight: 500, color: Colors.green_700}}>Order
                #{row.order_int}</Link> : "-",
        },
        {
            name: 'Amount ($)',
            selector: 'amount',
        },
        {
            name: 'Status',
            selector: 'status',
        },
    ]

    const {user_id} = props;

    const status = props.status || ""


    useEffect(() => {
        load(false)
    }, []);


    const load = loadMore => {
        let data = new FormData();
        data.append("user_id", user_id);
        data.append("status", status);
        data.append("offset", requestsListAll.length);

        setIsLoading(true);

        postData(Endpoints.TRANSACTION_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? requestsListAll : [];
                currentPosts = currentPosts.concat(...res.data);
                setRequestListAll(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <DataTable
            columns={columns}
            data={requestsListAll}
            pointerOnHover={true}
            className={"table table-striped table-bordered"}
            noHeader={true}
            onRowClicked={e => {
            }}
            customStyles={Styles.customTableStyles}
        />
    )
};


export default TransactionListSection