import Endpoints from "../Constants/Endpoints";

let socket;

let connect = (id, onCon, onData, onClose, onError) => {
    console.log("Attempting Connection...");
    socket = new WebSocket(Endpoints.WS_HOST + Endpoints.API_URL + Endpoints.WS+"?id="+id);

    window.ion.sound({
        sounds: [
            {name: "door_bell"}
        ],
        volume: 0.5,
        path:`${process.env.PUBLIC_URL}/assets/sounds/`,
        preload: true
    });

    socket.onopen = () => {
        console.log("Successfully Connected");
        onCon()
    };

    socket.onmessage = msg => {
        console.log("New message==>", msg.data);
        onData(msg)
    };

    socket.onclose = event => {
        console.log("Socket Closed Connection: ", event);
        onClose(event)
    };

    socket.onerror = error => {
        console.log("Socket Error: ", error);
        onError(error)
    };
};

let sendMsg = msg => {
    console.log("sending msg: ", msg);
    socket.send(msg);
};


export {connect, sendMsg};