import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import Popup from 'react-popup';
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";

const Dashboard = props => {

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState({});

    useEffect(() => {
        document.title = "Dashboard | " + Strings.APP_DESCRIPTION;
        loadStats()
    }, []);

    const loadStats = () => {
        let data = new FormData();
        setIsLoading(true);
        postData(Endpoints.GET_STATS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setStats(res.data)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <div className="page-content">
            <header className="page-content-header widgets-header">
                <div className="container-fluid">
                    <div className="tbl tbl-outer">
                        <div className="tbl-row">

                            <div className="tbl-cell">
                                <div className="tbl tbl-item">
                                    <div className="tbl-row">
                                        <div className="tbl-cell">
                                            <div className="title">
                                                <h5 style={{fontWeight: 700}}>Dashboard
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </header>


            <div className="container-fluid">

                <section className="tabs-section">
                    <div className="tabs-section-nav tabs-section-nav-inline">
                        <ul className="nav" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" href="#tabRequests" role="tab" data-toggle="tab">
                                    Orders
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#tabCustomers" role="tab" data-toggle="tab">
                                    Clients
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#tabRevenue" role="tab" data-toggle="tab">
                                    Revenue
                                </a>
                            </li>
                        </ul>
                    </div>

                    {!isLoading &&
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane fade in active show" id="tabRequests">
                            <div className="row">

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.today_requests}</div>
                                            <div className="caption">
                                                <div>Today</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.yester_day_requests}</div>
                                            <div className="caption">
                                                <div>Yesterday</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_today}</div>
                                            <div className="caption">
                                                <div>On Time Today</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_yesterday}</div>
                                            <div className="caption">
                                                <div>On Time Yesterday</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.this_week_requests}</div>
                                            <div className="caption">
                                                <div>This Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.last_week_requests}</div>
                                            <div className="caption">
                                                <div>Last Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_this_week}</div>
                                            <div className="caption">
                                                <div>On Time This Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_last_week}</div>
                                            <div className="caption">
                                                <div>On Time Last Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.this_month_requests}</div>
                                            <div className="caption">
                                                <div>This Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.last_month_requests}</div>
                                            <div className="caption">
                                                <div>Last Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_this_month}</div>
                                            <div className="caption">
                                                <div>On Time This Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-3">
                                    <article className="statistic-box purple">
                                        <div>
                                            <div className="number">{stats.on_time_last_month}</div>
                                            <div className="caption">
                                                <div>On Time Last Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade in" id="tabCustomers">
                            <div className="row">

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.today_clients}</div>
                                            <div className="caption">
                                                <div>Today</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.yester_day_clients}</div>
                                            <div className="caption">
                                                <div>Yesterday</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                             

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.this_week_clients}</div>
                                            <div className="caption">
                                                <div>This Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.last_week_clients}</div>
                                            <div className="caption">
                                                <div>Last Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.this_month_clients}</div>
                                            <div className="caption">
                                                <div>This Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box red">
                                        <div>
                                            <div className="number">{stats.last_month_clients}</div>
                                            <div className="caption">
                                                <div>Last Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                              
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade in" id="tabRevenue">
                            <div className="row">

                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.today_revenue}</div>
                                            <div className="caption">
                                                <div>Today</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.yester_day_revenue}</div>
                                            <div className="caption">
                                                <div>Yesterday</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>



                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.this_week_revenue}</div>
                                            <div className="caption">
                                                <div>This Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.last_week_revenue}</div>
                                            <div className="caption">
                                                <div>Last Week</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.this_month_revenue}</div>
                                            <div className="caption">
                                                <div>This Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-sm-6">
                                    <article className="statistic-box green">
                                        <div>
                                            <div className="number">{stats.last_month_revenue}</div>
                                            <div className="caption">
                                                <div>Last Month</div>
                                            </div>
                                        </div>
                                    </article>
                                </div>


                            </div>
                        </div>

                    </div>
                    }

                    {isLoading &&
                    <div style={{
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/>
                    </div>
                    }

                </section>

            </div>

        </div>
    )
};

export default Dashboard;
