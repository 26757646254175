import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {clearItem, retrieveItem} from "../../Utils/Storage";
import {useGlobal} from "../../Store";
import Dashboard from "../Dashboard";
import Login from "../Login";
import Settings from "../Settings/Settings";
import {connect, sendMsg} from "../../Utils/WSocket";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import Popup from 'react-popup';
import {goToPage} from "../../Utils/Common";
import RequestOverview from "../Requests/Overview";
import RequestsList from "../Requests/Listing";
import RequestDetails from "../Requests/Details";
import ReverseTimer from "../Requests/ReverseTimer";
import Search from "../Search/Search";
import ClientList from "../Clients/Listing";
import ClientDetails from "../Clients/Details";
import MessagesList from "../Messages/Listing";
import FinanceList from "../Finance/Listing";
import Broadcasts from "../Broadcasts/Broadcasts";
import Promo from "../Promo/Promo";


const Body = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();

    const checkUser = () => {
        let user = retrieveItem('user');
        if (user != null) {
            user = JSON.parse(user);
            let userLoggedIn = true;
            globalActions.setUserLoggedIn(userLoggedIn);
            globalActions.setCurrentUser(user);

            connectToSocket("admin")
            setTimeout(() => sendMsg("Hello"), 5000)
        }
        globalActions.setUserCheckComplete(true)
    };

    const onCon = () => {
        Notify('Connected', SUCCESS)

        setInterval(() => {
            sendMsg("alive")
        }, 3000)
    }

    const onError = e => {
        Notify('Connection lost', ERROR)
    }

    const onClose = e => {
        Notify('Connection lost', ERROR)
    }

    const connectToSocket = id => {
        connect(id, onCon, onData, onClose, onError)
    }

    const onData = e => {
        let data = JSON.parse(e.data)
        switch (data.data_type) {
            case "new_order":
                Notify(data.body, SUCCESS, true)
                Popup.create({
                    title: 'New order received',
                    content: data.body,
                    buttons: {
                        left: [{
                            text: 'View orders',
                            className: 'success',
                            action: function () {
                                Popup.close();
                                goToPage(history, "/orders/new")
                            }
                        }],
                        right: [{
                            text: 'Cancel',
                            className: '',
                            action: function () {
                                Popup.close();
                            }
                        }]
                    }
                });
                break
        }
    }


    useEffect(() => {
        checkUser()

    }, []);

    return (
        <main>
            <Switch>
                <Route exact path='/dashboard' component={Dashboard}/>


                <Route exact path='/messages' component={MessagesList}/>

                <Route exact path='/clients' component={ClientList}/>
                <Route exact path='/clients/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <ClientDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>

                <Route exact path='/orders' component={RequestOverview}/>
                <Route exact path='/orders/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RequestsList
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/order-details/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <RequestDetails
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/reverse-timer' component={ReverseTimer}/>
                <Route exact path='/search' component={Search}/>
                <Route exact path='/broadcasts' component={Broadcasts}/>
                <Route exact path='/finance' component={FinanceList}/>
                <Route exact path='/promotions' component={Promo}/>
                <Route exact path='/settings' component={Settings}/>

                <Route exact path='/' component={Login}/>
            </Switch>
        </main>
    )

};

export default Body;
