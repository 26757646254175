import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import Popup from 'react-popup';
import logo from '../../Assets/Images/logo.jpg';

const RequestDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [cancellationReason, setCancellationReason] = useState('');
    const [isCancelling, setIsCancelling] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('normal');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [noOfFiles, setNoOfFiles] = useState(0);
    const [messageList, setMessageList] = useState([]);

    const {id} = props.match.params;


    useEffect(() => {
        load(true)
    }, []);

    const load = (loadExtras = false) => {
        let data = new FormData();
        data.append("order_id", id);

        setIsLoading(true);
        postData(Endpoints.ORDER_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    updateDocumentTitle("Order #" + res.data.ID)
                    findOrderMessage(res.data.ID)

                    setInterval(() => {
                        findOrderMessage(res.data.ID)
                    }, 3000)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    const cancelOrder = () => {
        Popup.create({
            title: 'Cancel order',
            content: "Please confirm cancellation of this order",
            buttons: {
                left: [{
                    text: 'Cancel',
                    className: 'danger',
                    action: function () {
                        Popup.close();
                        initiateCancel()
                    }
                }],
                right: [{
                    text: 'Cancel',
                    className: '',
                    action: function () {
                        Popup.close();
                    }
                }]
            }
        });
    }

    const initiateCancel = () => {
        if (!(!!cancellationReason)) {
            Notify("Please enter cancellation reason", ERROR)
            return
        }

        setIsCancelling(true);

        let data = new FormData();
        data.append("order_id", id)
        data.append("reason", cancellationReason)

        postData(Endpoints.CANCEL_ORDER, data)
            .then(res => res.json())
            .then(res => {

                setIsCancelling(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    load()
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsCancelling(false);
                console.log(error)
            });

    }

    const findOrderMessage = (id = item.ID) => {

        let data = new FormData();
        data.append("order_id", id)

        postData(Endpoints.LIST_MESSAGES, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setMessageList(res.data.reverse() || [])
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }

    const updateFiles = files => {
        console.log(files)
        let uploaded = uploadedFiles
        for (let i = 0; i < files.length; i++) {
            uploaded.push({file: files[i], name: files[i].name})
        }
        setUploadedFiles(uploaded)
        setNoOfFiles(uploaded.length)
    }

    const removeFile = index => {
        let uploaded = []
        for (let i = 0; i < uploadedFiles.length; i++) {
            if (i !== index) {
                uploaded.push(uploadedFiles[i])
            }
        }
        setUploadedFiles(uploaded)
        setNoOfFiles(uploaded.length)
    }

    const sendMessage = () => {
        if (!(!!message)) {
            Notify("Type a message", ERROR)
            return
        }

        setIsSendingMessage(true);

        let data = new FormData();
        data.append("order_id", id)
        data.append("message", message)
        data.append("message_type", messageType)

        for (let i = 0; i < uploadedFiles.length; i++) {
            data.append(`message_files[${i}]`, uploadedFiles[i].file)
        }

        postData(Endpoints.SEND_MESSAGE, data)
            .then(res => res.json())
            .then(res => {

                setIsSendingMessage(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    setMessage('')
                    setUploadedFiles([])
                    findOrderMessage()
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsSendingMessage(false);
                console.log(error)
            });

    }

    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h6 style={{fontWeight: 700}}>Order #{item.ID}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-left mr-2">
                                                    <h6 style={{fontWeight: 700}}>{item.status}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/orders"
                                                          className="btn btn-success-outline">
                                                        Back to orders
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Overview
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabChat" role="tab" data-toggle="tab">
                                        Chat history
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Topic</td>
                                                <td className="color-black-blue">
                                                    <b>{item.topic}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Subject</td>
                                                <td className="color-black-blue">
                                                    <b>{item.subject_id}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Paper</td>
                                                <td className="color-black-blue"><b>{item.paper_id}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Level of education</td>
                                                <td className="color-black-blue"><b>{item.education_level}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Service type</td>
                                                <td className="color-black-blue"><b>{item.service_type}</b></td>
                                            </tr>
                                            <tr>
                                                <td>No of pages</td>
                                                <td className="color-black-blue"><b>{item.pages}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Cited resources</td>
                                                <td className="color-black-blue"><b>{item.cited_resources}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Citation format</td>
                                                <td className="color-black-blue"><b>{item.citation_format}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Paper instructions</td>
                                                <td className="color-black-blue"><b>{item.paper_instructions}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Uploaded files</td>
                                                <td className="color-black-blue">
                                                    {item.uploaded_files.map((i, k) =>
                                                            <span><a
                                                                target={"_"}
                                                                href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + i.file_name}
                                                                style={{
                                                                    color: Colors.green_700,
                                                                    textDecoration: 'underline'
                                                                }}>{i.file_name}</a><br/>
                                                    </span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Price</td>
                                                <td className="color-black-blue">
                                                    <b>${item.formatted_price}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Deadline in</td>
                                                <td className="color-black-blue">
                                                    <b>{item.remaining_hours}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Performance status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.performance_status}</b></td>
                                            </tr>
                                            </tbody>

                                        </table>
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Applied discount</td>
                                                <td className="color-black-blue">
                                                    <b>${item.applied_discount}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Promo code</td>
                                                <td className="color-black-blue">
                                                    <b>{item.promo_code}</b></td>
                                            </tr>
                                            <tr>
                                                <td>User cancelled</td>
                                                <td className="color-black-blue">
                                                    <b>{item.user_cancelled === 1 ? "YES" : "NO"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Admin cancelled</td>
                                                <td className="color-black-blue">
                                                    <b>{item.system_cancelled === 1 ? "YES" : "NO"}</b>
                                                    {item.user_cancelled === 1 && item.system_cancelled === 0 &&
                                                    <div>
                                                        <div className="form-group">
                                                            <label className="form-label col-form-label-sm">Cancellation
                                                                reason</label>
                                                            <textarea
                                                                required={true}
                                                                className="form-control"
                                                                placeholder="Enter cancellation reason"
                                                                value={cancellationReason}
                                                                onChange={e => setCancellationReason(e.target.value)}
                                                            />
                                                        </div>

                                                        {isLoading ?
                                                            <ProgressView size={23}
                                                                          style={{
                                                                              color: Colors.black,
                                                                              marginTop: 5
                                                                          }}/> :
                                                            <button type="submit" className="btn btn-danger"
                                                                    onClick={cancelOrder}
                                                            >Confirm cancellation</button>
                                                        }
                                                    </div>
                                                    }

                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Cancellation reason</td>
                                                <td className="color-black-blue">
                                                    <b>{item.cancellation_reason === "" ? "N/A" : item.cancellation_reason}</b>
                                                </td>
                                            </tr>


                                            </tbody>

                                        </table>

                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Work status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Client status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.delivery_status}</b></td>
                                            </tr>
                                            <tr>
                                                <td>SLWS status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status}</b></td>
                                            </tr>
                                            </tbody>

                                        </table>

                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Client name</td>
                                                <td className="color-black-blue">
                                                    <b><Link
                                                        to={"/clients/" + item.user_id}>{item.client_name}</Link></b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ratings</td>
                                                <td className="color-black-blue">
                                                    {item.status === 'COMPLETED' ? <b>{item.ratings} / 5</b> :
                                                        <b>N/A</b>}
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabChat">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mesgs" style="width: 100%;background: white"
                                             style={{width: '100%', backgroundColor: Colors.white}}>

                                            <div className="msg_history" style={{maxHeight: '50vh'}}>
                                                {messageList.map((i, k) => {
                                                    if (i.user_from !== "system") {
                                                        return (
                                                            <div className="incoming_msg" style={{marginTop: 5}}>
                                                                <div className="incoming_msg_img">

                                                                    {i.type === "delivery" ?
                                                                        <i className="fa fa-check-circle fa-3x"
                                                                           style={{color: Colors.green_700}}></i> :
                                                                        <img src={logo} alt="sunil"
                                                                             style={{width: 50, height: 50}}/>}
                                                                </div>
                                                                <div className="received_msg"
                                                                     style={{wordWrap: 'break-word'}}>
                                                                    <div className="received_withd_msg">

                                                                        <p style={{
                                                                            textTransform: 'none',
                                                                            fontSize: 13,
                                                                            backgroundColor: "#c4c4c4"
                                                                        }}> {i.not_text}
                                                                            {(i.files || []).length > 0 ? <br/> :
                                                                                <span></span>}

                                                                            {(i.files || []).map((a, b) =>
                                                                                <span style={{marginTop: 10}}>
                                                                                <br/>{b + 1}.<a target={"_"}
                                                                                                href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + a.file_name}
                                                                                                style={{
                                                                                                    color: Colors.black,
                                                                                                    textDecoration: 'underline'
                                                                                                }}>{a.file_name}</a></span>
                                                                            )}

                                                                            <br/>
                                                                        </p>
                                                                        <span
                                                                            className="time_date"> {i.ago}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="outgoing_msg">
                                                                <div className="sent_msg"
                                                                     style={{wordWrap: 'break-word'}}>

                                                                    <p style={{
                                                                        textTransform: 'none',
                                                                        fontSize: 13,
                                                                    }}>  {i.type === "delivery" ?
                                                                        <i className="fa fa-check-circle fa-3x"
                                                                           style={{color: Colors.green_700}}></i> :
                                                                        <img src={logo} alt="sunil"
                                                                             style={{width: 50, height: 50}}/>}
                                                                        {" " + i.not_text}
                                                                        {(i.files || []).length > 0 ? <br/> :
                                                                            <span></span>}

                                                                        {(i.files || []).map((a, b) =>
                                                                            <span style={{marginTop: 10}}>
                                                                                <br/>{b + 1}.<a target={"_"}
                                                                                                href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + a.file_name}
                                                                                                style={{
                                                                                                    color: Colors.white,
                                                                                                    textDecoration: 'underline'
                                                                                                }}>{a.file_name}</a></span>
                                                                        )}

                                                                        <br/>

                                                                    </p>

                                                                    <span className="time_date"> {i.ago}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>

                                            <form className="c-form" id="chartForm">
                                                <label>You may leave a message on this order</label>


                                                <div className="form-group">
                                                <textarea type="text"
                                                          className="form-control"
                                                          placeholder="Write a message"
                                                          style={{
                                                              maxWidth: '100%', maxHeight: '300px', minWidth: '100%'
                                                          }}
                                                          onChange={e => setMessage(e.target.value)}
                                                          value={message}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">Message type</label>
                                                    <select className="form-control"
                                                            name="message_type"
                                                            onChange={e => setMessageType(e.target.value)}
                                                            value={messageType}
                                                    >
                                                        <option value="message">Normal message</option>
                                                        <option value="delivery">Delivery message</option>
                                                    </select>
                                                </div>


                                                {isSendingMessage &&
                                                <div style={{textAlign: 'center'}}>
                                                    <ProgressView size={23}
                                                                  style={{color: Colors.black, marginTop: 5}}/>
                                                </div>
                                                }

                                                {!isSendingMessage &&
                                                <div className="form-group text-right">
                                                    {uploadedFiles.map((i, k) => <p><i
                                                        className="fa fa-file"></i> {i.name} <i
                                                        style={{cursor: 'pointer', color: Colors.red_600}}
                                                        onClick={e => {
                                                            removeFile(k)
                                                        }}
                                                        className="fa fa-trash"></i></p>)}

                                                    <span
                                                        className="btn btn-sm rounded dark fileinput-button text-dark"
                                                        style={{
                                                            overflow: 'hidden',
                                                            backgroundColor: Colors.white,
                                                            color: Colors.black
                                                        }}>
                                                    <i className="fa fa-files-o"></i><span
                                                        style={{color: Colors.black}}> Attach Files</span>
                                                    <input id="fileUpload"
                                                           type="file"
                                                           name="file"
                                                           multiple
                                                           onChange={e => {
                                                               updateFiles(e.target.files)
                                                           }}/>
                                                    </span>
                                                    <span className="btn btn-sm rounded dark-bg text-white"
                                                          onClick={e => {
                                                              e.preventDefault()
                                                              sendMessage()
                                                          }}
                                                          style={{color: Colors.white}} id="sendChatMessage"><i
                                                        className="fa fa-send"></i> Send Message</span>
                                                </div>
                                                }

                                            </form>

                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                    </div>

                                </div>

                            </div>

                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};

export default RequestDetails