import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";
import OrderListSection from "../Requests/OrderListSection";
import TransactionListSection from "../Finance/TransactionListSection";


const ClientDetails = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({title: ''});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [topUpAmount, setTopUpAmount] = useState('');
    const [topUpRef, setTopRef] = useState('');

    const columns = [
        {
            name: 'ID',
            sortable: true,
            cell: row => <div><Link to={"/order-details/" + row.string_id}>Order #{row.ID}</Link></div>,
        },
        {
            name: 'Price ($)',
            selector: 'formatted_price',
        },
        {
            name: 'Status',
            selector: 'delivery_status',
        },
        {
            name: 'Time Remaining',
            selector: 'remaining_hours',
        }
    ]

    const {id} = props.match.params;

    const load = () => {
        let data = new FormData();
        data.append("id", id);

        setIsLoading(true);
        postData(Endpoints.CLIENT_DETAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setItem(res.data);
                    setName(res.data.name);
                    setPhone(res.data.phone);
                    setEmail(res.data.email);
                    updateDocumentTitle(res.data.name)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        load()
    }, []);

    function updateStatus(number) {
        let data = new FormData();
        data.append("id", id);
        data.append("status", number);

        setIsLoading(true);
        postData(Endpoints.CLIENT_UPDATE_STATUS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }

    function topUpUser() {
        if (!(!!topUpAmount)) {
            Notify("Amount is required", ERROR)
            return
        }

        if (!(!!topUpRef)) {
            Notify("Reference is required", ERROR)
            return
        }

        let data = new FormData();
        data.append("id", id);
        data.append("amount", topUpAmount);
        data.append("reference", topUpRef);

        setIsLoading(true);
        postData(Endpoints.CLIENT_UPDATE_WALLET, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                Notify(SUCCESS ? res.data : res.message, res.success === 1 ? SUCCESS : ERROR);

                if (res.success === 1) {
                    setTopRef('')
                    setTopUpAmount('')
                    load()
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            {item.title !== '' &&
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-center">
                                                    {isLoading &&
                                                    <ProgressView size={23} style={{color: Colors.black, margin: 10}}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    {!isLoading &&
                                                    <button type="submit"
                                                            className={item.status === 1 ? "btn btn-danger" : "btn btn-success"}
                                                            onClick={e => updateStatus(item.status === 1 ? 0 : 1)}>{item.status === 1 ? "Deactivate" : "Activate"}</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <Link to="/clients"
                                                          className="btn btn-success-outline">
                                                        Back to clients
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabDetails" role="tab" data-toggle="tab">
                                        Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabTopUp" role="tab" data-toggle="tab">
                                        Top up
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequestsNew" role="tab" data-toggle="tab">
                                        New orders
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequestsOngoing" role="tab" data-toggle="tab">
                                        Ongoing orders
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabRequestsAll" role="tab" data-toggle="tab">
                                        Completed orders
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabTransactions" role="tab" data-toggle="tab">
                                        Transactions
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active show" id="tabDetails">
                                <div className="row">
                                    <div className="col-md-7">
                                        <table className="mb-3 table table-bordered table-hover table-sm dataT">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="color-black-blue">
                                                    <b>{item.name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td className="color-black-blue">
                                                    <b>{item.email}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Total Balance</td>
                                                <td className="color-black-blue">
                                                    <b>USD {item.balance}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Usable Balance</td>
                                                <td className="color-black-blue">
                                                    <b>USD {item.usable_balance}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td className="color-black-blue">
                                                    <b>{item.status === 0 ? "INACTIVE" : "ACTIVE"}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Registered on</td>
                                                <td className="color-black-blue">
                                                    <b>{item.date_added}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRequestsNew">
                                <OrderListSection status={"new"} user_id={id}/>
                            </div>
                            <div role="tabpanel" className="tab-pane fade in" id="tabRequestsOngoing">
                                <OrderListSection status={"ongoing"} user_id={id}/>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabRequestsAll">
                                <OrderListSection status={"completed"} user_id={id}/>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabTransactions">
                                <TransactionListSection user_id={id} status={"completed"}/>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabTopUp">

                                <div className="card-block">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">


                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Top up
                                                        amount</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           onChange={e => setTopUpAmount(e.target.value)}
                                                           value={topUpAmount}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label col-form-label-sm">Transaction
                                                        reference</label>
                                                    <input type="text"
                                                           required
                                                           className="form-control"
                                                           onChange={e => setTopRef(e.target.value)}
                                                           value={topUpRef}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <p><small>*The client will receive an email confirming this
                                                        transaction</small></p>

                                                    {isLoading ?
                                                        <ProgressView size={23}
                                                                      style={{color: Colors.black, marginTop: 5}}/> :
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={topUpUser}
                                                        >Top up</button>
                                                    }
                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>


                            </div>


                        </div>
                    </section>

                </div>

            </div>
            }
        </AuthProvider>
    )
};


export default ClientDetails