import Colors from "./Colors";

const Styles = {
    activeLinkStyle: {
        color: Colors.ascent,
        fontWeight: '800',
    },
    headerMenuStyle: {
        fontWeight: 600,
        letterSpacing: 1.3,
        fontSize: 15,
        textTransform: 'uppercase',
    },
    activeHeaderLinkStyle: {
        fontWeight: 800,
        letterSpacing: 1.3,
        fontSize: 15,
        textTransform: 'uppercase',
    },
};

const customTableStyles = {
    headCells: {
        style: {
            fontSize: 16,
            fontWeight: 700,
        },
    },
    cells: {
        style: {
            fontSize: 16
        },
    },
};

export default {Styles, customTableStyles}