import Endpoints from "../Constants/Endpoints";
import {storeItem} from "./Storage";

export const handleUserAccess = (res, globalActions, redToAccount) => {
    let user = res.data.admin;
    let token = res.data.token;

    storeItem('user', JSON.stringify(user));
    storeItem('token', token);
    globalActions.setCurrentUser({});
    globalActions.setCurrentUser(user);
    globalActions.setUserLoggedIn(true);

    if (redToAccount) {
        window.location.assign(process.env.PUBLIC_URL + "/dashboard")
    }
};
