import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import {updateDocumentTitle} from "../../Utils/Common";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import OrderListSection from "./OrderListSection";

const RequestsList = props => {
    let history = useHistory();

    const {id} = props.match.params;
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        updateDocumentTitle(id.toUpperCase())
    }, []);


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>{id.toUpperCase()}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                {isLoading &&
                                                <ProgressView size={23}
                                                              style={{color: Colors.black, marginTop: 5}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>


                <div className="container-fluid">
                    <section className="panel panel-default">
                        <div className="panel-body">
                            <OrderListSection status={id} user_id={0}/>
                        </div>

                    </section>
                </div>

            </div>
        </AuthProvider>
    )
};

export default RequestsList;

