import React, {useEffect, useState} from "react";
import Select from 'react-select';
import Colors from "../../Constants/Colors";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const DatePicker = props => {

    let d = new Date();

    const [pickerDate, setPickerDate] = useState(d.getDate());
    const [pickerMonth, setPickerMonth] = useState(d.getMonth() + 1);
    const [pickerYear, setPickerYear] = useState(d.getFullYear());

    useEffect(() => {
        updatePickerDate(pickerDate, pickerMonth, pickerYear)
    }, [])

    const updatePickerDate = (pickerDate, pickerMonth, pickerYear) => props.onDateSelected(formatData(pickerDate), formatData(pickerMonth), formatData(pickerYear))

    const formatData = i => i.toString().length > 1 ? i : "0" + i

    return (
        <div className={"row"}>
            <div className="form-group col-md-4">
                <label className="form-label col-form-label-sm" style={{fontSize:13,fontStyle:'italic', fontWeight:600}}>Day</label>
                <select className="form-control"
                        value={pickerDate}
                        onChange={({target}) => {
                            setPickerDate(target.value)
                            updatePickerDate(target.value, pickerMonth, pickerYear)
                        }}>
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                    <option value={"3"}>3</option>
                    <option value={"4"}>4</option>
                    <option value={"5"}>5</option>
                    <option value={"6"}>6</option>
                    <option value={"7"}>7</option>
                    <option value={"8"}>8</option>
                    <option value={"9"}>9</option>
                    <option value={"10"}>10</option>
                    <option value={"11"}>11</option>
                    <option value={"12"}>12</option>
                    <option value={"13"}>13</option>
                    <option value={"14"}>14</option>
                    <option value={"15"}>15</option>
                    <option value={"16"}>16</option>
                    <option value={"17"}>17</option>
                    <option value={"18"}>18</option>
                    <option value={"19"}>19</option>
                    <option value={"20"}>20</option>
                    <option value={"21"}>21</option>
                    <option value={"22"}>22</option>
                    <option value={"23"}>23</option>
                    <option value={"24"}>24</option>
                    <option value={"25"}>25</option>
                    <option value={"26"}>26</option>
                    <option value={"27"}>27</option>
                    <option value={"28"}>28</option>
                    <option value={"29"}>29</option>
                    <option value={"30"}>30</option>
                    <option value={"31"}>31</option>
                </select>
            </div>
            <div className="form-group col-md-4">
                <label className="form-label col-form-label-sm" style={{fontSize:13,fontStyle:'italic', fontWeight:600}}>Month</label>
                <select className="form-control"
                        value={pickerMonth}
                        onChange={({target}) => {
                            setPickerMonth(target.value)
                            updatePickerDate(pickerDate, target.value, pickerYear)
                        }}>
                    <option value={1}>Jan</option>
                    <option value={2}>Feb</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                </select>
            </div>

            <div className="form-group col-md-4">
                <label className="form-label col-form-label-sm" style={{fontSize:13,fontStyle:'italic', fontWeight:600}}>Year</label>
                <select className="form-control"
                        value={pickerYear}
                        onChange={({target}) => {
                            setPickerYear(target.value)
                            updatePickerDate(pickerDate, pickerMonth, target.value)
                        }}>
                    <option value={"2020"}>2020</option>
                    <option value={"2021"}>2021</option>
                </select>
            </div>
        </div>
    )
};

export default DatePicker;