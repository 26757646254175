import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";

const AuthProvider = props => {

    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if (globalState.userCheckComplete && !globalState.userLoggedIn) {
            window.location.assign(process.env.PUBLIC_URL + "/");
        }
    }, [globalState.userCheckComplete]);

    return (
        <div>
            {globalState.userCheckComplete && globalState.userLoggedIn &&
            <div>
                {props.children}
            </div>
            }
        </div>
    )
};

export default AuthProvider;