const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default {
    SERVER_HOST: IS_DEV ? 'http://localhost:9090' : "https://testapi.slws.co.ke",
    WS_HOST: IS_DEV ? 'ws://localhost:9090' : "wss://testapi.slws.co.ke",
    API_URL: '/api/v1/',
    FILE_URL: '/files/',
    WS: 'ws',
    LOGIN: 'admin/login',
    UPDATE_PROFILE: 'admin/update/profile',
    UPDATE_PASSWORD: 'admin/update/password',

    PAPER_ADD: 'paper/new',
    PAPER_LIST: 'paper/list',
    PAPER_DELETE: 'paper/delete',

    SUBJECT_ADD: 'subject/new',
    SUBJECT_LIST: 'subject/list',
    SUBJECT_DELETE: 'subject/delete',

    RATE_ADD: 'rate/new',
    RATE_LIST: 'rate/list',
    RATE_DELETE: 'rate/delete',


    ORDER_LIST: 'order/list',
    ORDER_STATS: 'order/list-stats',
    REQUEST_TIMER: 'request/list-admin-timer',
    ORDER_DETAIL: 'order/find',
    CANCEL_ORDER: 'order/cancel-admin',
    SEND_MESSAGE: 'message/send-admin',
    LIST_MESSAGES: 'message/get-order-admin',
    LIST_ALL_MESSAGES: 'message/get-admin',

    CLIENT_LIST: 'user/list',
    CLIENT_DETAIL: 'user/find',
    CLIENT_UPDATE_STATUS: 'user/update/status',
    CLIENT_UPDATE_WALLET: 'user/update/wallet',

    GET_STATS: 'stats',

    SEND_BROADCAST: 'broadcast/new',
    LIST_BROADCAST: 'broadcast/list',
    LIST_SCHEDULED_BROADCAST: 'broadcast/scheduled',
    DELETE_BROADCAST: 'broadcast/delete',

    LIST_SETTINGS: 'settings/list',
    UPDATE_SETTINGS: 'settings/update',

    NEW_PROMO: 'promo/new',
    LIST_PROMO: 'promo/list',
    DELETE_PROMO: 'promo/delete',

    LIST_USER_MESSAGES_COUNT: 'message/get-admin-count',
    TRANSACTION_LIST: 'transaction/list-admin',
    REVENUE_LIST: 'revenue/monthly',

};
