import useGlobalHook from "use-global-hook";
import React from "react";

const initialState = {
    cartItems: [],
    numberOfCartItems: 0,
    cartTotal: 0.00,
    currentCartId: '',
    currentUser: {},
    userLoggedIn: false,
    userCheckComplete: false,
    siteData: {
        header_data: [],
        footer_data: []
    },
    activeCategory: '',
    collections: [],
    categories: [],
    subCategories: [],
    cartItem: null,
    notif: {new_message: 0, new_orders: 0}
};

export const actions = {
    setProfileMenu: (store, items) => {
        store.setState({profileMenu: items});
    },
    setSiteData: (store, data) => {
        store.setState({siteData: data});
    },
    setCurrentCartId: (store, id) => {
        store.setState({currentCartId: id});
    },
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user});
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn});
    },
    setUserCheckComplete: (store, complete) => {
        store.setState({userCheckComplete: complete});
    },
    setActiveCategory: (store, category) => {
        store.setState({activeCategory: category});
    },
    setCollections: (store, cols) => {
        store.setState({collections: cols});
    },
    setCategories: (store, cats) => {
        store.setState({categories: cats});
    },
    setSubCategories: (store, subss) => {
        store.setState({subCategories: subss});
    },
    setNot: (store, not) => {
        store.setState({notif: not});
    },
};

export const useGlobal = useGlobalHook(React, initialState, actions);
