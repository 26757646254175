import React, {useEffect, useState} from "react";
import Select from 'react-select';
import Colors from "../../Constants/Colors";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const TimePicker = props => {

    let d = new Date();

    const [pickerTime, setPickerTime] = useState(d.getHours());
    const [pickerMinute, setPickerMinute] = useState(d.getMinutes());

    useEffect(() => {
        updatePickerDate(pickerTime, pickerMinute)
    }, [])

    const updatePickerDate = (pickerTime, pickerMinute) => props.onTimeSelected(formatData(pickerTime), formatData(pickerMinute))

    const formatData = i => i.toString().length > 1 ? i : "0" + i

    return (
        <div className={"row"}>

            <div className="form-group col-md-6">
                <label className="form-label col-form-label-sm"
                       style={{fontSize: 13, fontStyle: 'italic', fontWeight: 600}}>Hour (24 Hrs)</label>
                <select className="form-control"
                        value={pickerTime}
                        onChange={({target}) => {
                            setPickerTime(target.value)
                            updatePickerDate(target.value, pickerMinute)
                        }}>
                    <option value={"0"}>00h</option>
                    <option value={"1"}>01h</option>
                    <option value={"2"}>02h</option>
                    <option value={"3"}>03h</option>
                    <option value={"4"}>04h</option>
                    <option value={"5"}>05h</option>
                    <option value={"6"}>06h</option>
                    <option value={"7"}>07h</option>
                    <option value={"8"}>08h</option>
                    <option value={"9"}>09h</option>
                    <option value={"10"}>10h</option>
                    <option value={"11"}>11h</option>
                    <option value={"12"}>12h</option>
                    <option value={"13"}>13h</option>
                    <option value={"14"}>14h</option>
                    <option value={"15"}>15h</option>
                    <option value={"16"}>16h</option>
                    <option value={"17"}>17h</option>
                    <option value={"18"}>18h</option>
                    <option value={"19"}>19h</option>
                    <option value={"20"}>20h</option>
                    <option value={"21"}>21h</option>
                    <option value={"22"}>22h</option>
                    <option value={"23"}>23h</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <label className="form-label col-form-label-sm"
                       style={{fontSize: 13, fontStyle: 'italic', fontWeight: 600}}>Minutes</label>
                <select className="form-control"
                        value={pickerMinute}
                        onChange={({target}) => {
                            setPickerMinute(target.value)
                            updatePickerDate(target.value, pickerMinute)
                        }}>
                    <option value={"0"}>00m</option>
                    <option value={"1"}>01m</option>
                    <option value={"2"}>02m</option>
                    <option value={"3"}>03m</option>
                    <option value={"4"}>04m</option>
                    <option value={"5"}>05m</option>
                    <option value={"6"}>06m</option>
                    <option value={"7"}>07m</option>
                    <option value={"8"}>08m</option>
                    <option value={"9"}>09m</option>
                    <option value={"10"}>10m</option>
                    <option value={"11"}>11m</option>
                    <option value={"12"}>12m</option>
                    <option value={"13"}>13m</option>
                    <option value={"14"}>14m</option>
                    <option value={"15"}>15m</option>
                    <option value={"16"}>16m</option>
                    <option value={"17"}>17m</option>
                    <option value={"18"}>18m</option>
                    <option value={"19"}>19m</option>
                    <option value={"20"}>20m</option>
                    <option value={"21"}>21m</option>
                    <option value={"22"}>22m</option>
                    <option value={"23"}>23m</option>
                    <option value={"24"}>24m</option>
                    <option value={"25"}>25m</option>
                    <option value={"26"}>26m</option>
                    <option value={"27"}>27m</option>
                    <option value={"28"}>28m</option>
                    <option value={"29"}>29m</option>
                    <option value={"30"}>30m</option>
                    <option value={"31"}>31m</option>
                    <option value={"32"}>32m</option>
                    <option value={"33"}>33m</option>
                    <option value={"34"}>34m</option>
                    <option value={"35"}>35m</option>
                    <option value={"36"}>36m</option>
                    <option value={"37"}>37m</option>
                    <option value={"38"}>38m</option>
                    <option value={"39"}>39m</option>
                    <option value={"40"}>40m</option>
                    <option value={"41"}>41m</option>
                    <option value={"42"}>42m</option>
                    <option value={"43"}>43m</option>
                    <option value={"44"}>44m</option>
                    <option value={"45"}>45m</option>
                    <option value={"46"}>46m</option>
                    <option value={"47"}>47m</option>
                    <option value={"48"}>48m</option>
                    <option value={"49"}>49m</option>
                    <option value={"50"}>50m</option>
                    <option value={"51"}>51m</option>
                    <option value={"52"}>52m</option>
                    <option value={"53"}>53m</option>
                    <option value={"54"}>54m</option>
                    <option value={"55"}>55m</option>
                    <option value={"56"}>56m</option>
                    <option value={"57"}>57m</option>
                    <option value={"58"}>58m</option>
                    <option value={"59"}>59m</option>
                </select>
            </div>

        </div>
    )
};

export default TimePicker;