import React, {useEffect, useState} from 'react';
import Header from "./Components/Layout/Header";
import {ToastHolder} from "./Utils/Notify";
import ScrollToTop from "./Components/Layout/ScrollToTop";
import Colors from "./Constants/Colors";
import Body from "./Components/Layout/Body";
import {useLocation} from "react-router-dom";
import Popup from 'react-popup';

function App() {
    let location = useLocation();
    const [isIndex, setIsIndex] = useState(location.pathname === "/" || location.pathname === "/register");

    return (
        <body
            className={isIndex ? "dark-theme theme-side-caesium-dark-caribbean" : "with-side-menu dark-theme theme-side-caesium-dark-caribbean"}
            style={{backgroundColor: isIndex ? Colors.white : Colors.grey_100}}>
        <Popup className="mm-popup"
               btnClass="mm-popup__btn"
               closeBtn={true}
               closeHtml={null}/>
        <Header/>
        <Body/>
        <ToastHolder/>
        <ScrollToTop/>
        </body>
    );
}

export default App;
