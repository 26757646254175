import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Colors from "../../Constants/Colors";
import avatar from "../../Assets/Images/a3.png";
import SideBarNav from "./SideBarNav";
import AuthProvider from "../Views/AuthProvider";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom'

const Header = props => {

    const [globalState, globalActions] = useGlobal();
    const [appName, setAppName] = useState('SLWS')

    let location = useLocation();
    const [isIndex, setIsIndex] = useState(location.pathname === "/" || location.pathname === "/register");

    return (
        <div>
            {!isIndex &&
            <AuthProvider>
                <header className="site-header" style={{
                    backgroundColor: "#d1410c",
                    backgroundImage: "linear-gradient(to left, rgb(209, 66, 37), rgba(42, 1, 121, 1))"
                }}>
                    <div className="container-fluid">
                        <a href="#" className="site-logo-text"
                           style={{
                               backgroundColor: "#1008299e",
                               padding: 3,
                           }}
                        >
                            {appName}</a>

                        <button className="hamburger hamburger--htla">
                            <span>toggle menu</span>
                        </button>
                        <div className="site-header-content">
                            <div className="site-header-content-in">
                                <div className="site-header-shown">
                                    <div className="dropdown user-menu">
                                        <button className="dropdown-toggle" id="dd-user-menu" type="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <img src={avatar} alt="" className="userPhoto"/>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right"
                                             aria-labelledby="dd-user-menu">
                            <span className="dropdown-item userName"
                                  style={{fontWeight: 'bolder', fontSize: 'large'}}
                            >{globalState.currentUser.name}</span>
                                            <span
                                                className="dropdown-item userEmail">{globalState.currentUser.email}</span>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to={"/settings"}><span
                                                className="font-icon glyphicon glyphicon-cog"></span>Profile
                                                Settings</Link>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" href="#" onClick={e => {
                                                e.preventDefault();
                                                localStorage.clear();
                                                window.location.assign(process.env.PUBLIC_URL + "/");
                                            }}><span
                                                className="font-icon glyphicon glyphicon-log-out"></span>Logout</a>
                                        </div>
                                    </div>

                                    <button type="button" className="burger-right">
                                        <i className="font-icon-menu-addl"></i>
                                    </button>
                                </div>

                                <div className="mobile-menu-right-overlay"></div>
                                <div className="site-header-collapsed">
                                    <div className="site-header-collapsed-in">

                                        <div className="dropdown">
                                            <Link className="btn dropdown-toggle" id="dd-header-add" type="button"
                                                  style={{
                                                      backgroundColor: 'black',
                                                      border: 'none',
                                                      marginLeft: 20
                                                  }} to={"/search"}>
                                                SEARCH
                                            </Link>
                                            <Link className="btn dropdown-toggle" id="dd-header-add" type="button"
                                                  style={{
                                                      backgroundColor: 'black',
                                                      border: 'none',
                                                      marginLeft: 20
                                                  }} to={"/orders"}>
                                                ORDERS BOARD
                                            </Link>
                                            <Link className="btn dropdown-toggle" id="dd-header-add" type="button"
                                                  style={{
                                                      backgroundColor: 'black',
                                                      border: 'none',
                                                      marginLeft: 20
                                                  }} to={"/reverse-timer"}>
                                                REQUEST TIMER
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-menu-left-overlay"></div>

                <SideBarNav/>
            </AuthProvider>
            }
        </div>
    )
};

export default Header;
