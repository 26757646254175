import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../../Constants/Colors";

const ProgressView = props => {

    return (
        <CircularProgress color={Colors.black} {...props}/>
    )
};

export default ProgressView;