import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {Link} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import AuthProvider from "../Views/AuthProvider";
import {goToPage, updateDocumentTitle} from "../../Utils/Common";
import {useHistory} from "react-router-dom";

const RequestOverview = props => {

    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');
    let lastLoaded = new Date();

    useEffect(() => {
        updateDocumentTitle("Order overview")
        loadCounter()
    }, []);

    function loadCounter() {
        let data = new FormData();
        data.append("status", 1);
        setIsLoading(true)
        postData(Endpoints.ORDER_STATS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    setStats(res.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Order overview
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">

                                                {isLoading &&
                                                <ProgressView size={23}
                                                              style={{color: Colors.black, marginTop: 5}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title text-right">
                                                    <button onClick={e => loadCounter()}
                                                            className="btn btn-success">
                                                        Reload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">

                                <div className="col-sm-4">
                                    <Link to={"/orders/new"}>
                                        <article className="statistic-box red">
                                            <div>
                                                <div className="number">{stats.new}</div>
                                                <div className="caption">
                                                    <div>New / Unassigned</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-4">
                                    <Link to={"/orders/ongoing"}>
                                        <article className="statistic-box green">
                                            <div>
                                                <div className="number">{stats.ongoing}</div>
                                                <div className="caption">
                                                    <div>Ongoing / Assigned</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/orders/in-revision"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.revision}</div>
                                                <div className="caption">
                                                    <div>In Revision</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>


                                <div className="col-sm-2">
                                    <Link to={"/orders/delivered"}>
                                        <article className="statistic-box bg-dark text-white">
                                            <div>
                                                <div className="number">{stats.delivered}</div>
                                                <div className="caption">
                                                    <div>Delivered</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Link to={"/orders/late"}>
                                        <article className="statistic-box bg-danger text-white">
                                            <div>
                                                <div className="number">{stats.late}</div>
                                                <div className="caption">
                                                    <div>Late & Undelivered</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>



                                <div className="col-sm-2">
                                    <Link to={"/orders/cancel-requests"}>
                                        <article className="statistic-box red">
                                            <div>
                                                <div className="number">{stats.cancel_requests}</div>
                                                <div className="caption">
                                                    <div>Cancel Requests</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>

                                  <div className="col-sm-4">
                                    <Link to={"/orders/cancelled"}>
                                        <article className="statistic-box bg-danger">
                                            <div>
                                                <div className="number">{stats.cancelled}</div>
                                                <div className="caption">
                                                    <div>Cancelled</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>


                                  <div className="col-sm-4">
                                    <Link to={"/orders/completed"}>
                                        <article className="statistic-box green">
                                            <div>
                                                <div className="number">{stats.completed}</div>
                                                <div className="caption">
                                                    <div>Completed</div>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                </div>



                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </AuthProvider>
    )
};

export default RequestOverview;

