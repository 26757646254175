import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {updateDocumentTitle} from "../../Utils/Common";


const OrderListSection = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    const [requestsListAll, setRequestListAll] = useState([]);

    const columns = [
        {
            name: 'ID',
            sortable: true,
            cell: row => <div><Link to={"/order-details/" + row.string_id}>Order #{row.ID}</Link></div>,
        },
        {
            name: 'Price ($)',
            selector: 'formatted_price',
        },
        {
            name: 'Work Status',
            selector: 'status',
        },
        {
            name: 'Client Status',
            selector: 'delivery_status',
        },
        {
            name: 'Time Remaining',
            selector: 'remaining_hours',
        },
        {
            name: 'To SLWS',
            cell: row => <p>{row.slws_ref === 0 ? "NO" : "YES"}</p>,
        }
    ]

    const {user_id, status} = props;


    useEffect(() => {
        load(false)
    }, []);


    const load = loadMore => {
        let data = new FormData();
        data.append("user_id", user_id);
        data.append("type", status);
        data.append("offset", requestsListAll.length);

        setIsLoading(true);

        postData(Endpoints.ORDER_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? requestsListAll : [];
                currentPosts = currentPosts.concat(...res.data);
                setRequestListAll(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };


    return (
        <DataTable
            columns={columns}
            data={requestsListAll}
            pointerOnHover={true}
            className={"table table-striped table-bordered"}
            noHeader={true}
            onRowClicked={e => {
                history.push('/order-details/' + e.string_id);
            }}
            customStyles={Styles.customTableStyles}
        />
    )
};


export default OrderListSection