import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Strings from "../../Constants/Strings";
import {Link} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import DataTable from "react-data-table-component";
import Styles from "../../Constants/Styles";
import {useHistory} from "react-router-dom";
import {updateDocumentTitle} from "../../Utils/Common";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import TransactionListSection from "./TransactionListSection";
import OrderListSection from "../Requests/OrderListSection";

const FinanceList = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [activeList, setActiveList] = useState([]);
    const [financeList, setFinanceList] = useState([]);
    const columns = [
        {
            name: 'Month',
            selector: 'name',
            sortable: true,
            cell: row => <p>{row.month_data.Name}</p>,
        },
        {
            name: 'Completed orders',
            selector: 'orders',
        },
        {
            name: 'Revenue ($)',
            selector: 'total_revenue_string',
        }
    ]

    const load = (loadMore, status) => {
        let data = new FormData();
        data.append("status", status);
        setIsLoading(true);
        postData(Endpoints.REVENUE_LIST, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                let currentPosts = loadMore ? financeList : [];
                currentPosts = currentPosts.concat(...res.data);
                setFinanceList(currentPosts);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });
    };

    useEffect(() => {
        updateDocumentTitle("Finance")
        load(false, 1);
    }, []);

    return (
        <AuthProvider>
            <div className="page-content">

                <header className="page-content-header widgets-header">
                    <div className="container-fluid">
                        <div className="tbl tbl-outer">
                            <div className="tbl-row">

                                <div className="tbl-cell">
                                    <div className="tbl tbl-item">
                                        <div className="tbl-row">
                                            <div className="tbl-cell">
                                                <div className="title">
                                                    <h5 style={{fontWeight: 700}}>Finance
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">

                    <section className="tabs-section">
                        <div className="tabs-section-nav tabs-section-nav-inline">
                            <ul className="nav" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#tabMonthly" role="tab" data-toggle="tab">
                                        Monthly revenue
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabCompleted" role="tab" data-toggle="tab">
                                        Completed orders
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tabTransactions" role="tab" data-toggle="tab">
                                        Transactions
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">

                            <div role="tabpanel" className="tab-pane fade in active show" id="tabMonthly">

                                <DataTable
                                    columns={columns}
                                    data={financeList}
                                    pointerOnHover={true}
                                    className={"table table-striped table-bordered"}
                                    noHeader={true}
                                    onRowClicked={e => {
                                        history.push('/clients/' + e.ID);
                                    }}
                                    customStyles={Styles.customTableStyles}
                                />


                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabTransactions">
                                <TransactionListSection user_id={0} status={"completed"}/>
                            </div>

                            <div role="tabpanel" className="tab-pane fade in" id="tabCompleted">
                                <OrderListSection status={"completed"}/>
                            </div>


                        </div>
                    </section>

                </div>

            </div>
        </AuthProvider>
    )
};

export default FinanceList;

